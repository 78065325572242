import React from 'react';
import ReactDOM from 'react-dom';
import Logo_White from '@src/asset/Logo_White.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './footer.css';
import { Link } from "gatsby"
import Container from '@material-ui/core/Container';

import { useIntl } from "gatsby-plugin-react-intl"

const footer_logo_xs = 10
const footer_logo_sm = 10
const footer_logo_md = 3
const footer_logo_lg = 3
const footer_logo_xl = 3

const footer_xs = 10
const footer_sm = 10
const footer_md = 3
const footer_lg = 3
const footer_xl = 3





const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footerContentResp: {
    [theme.breakpoints.down('sm')]: {
      textAlign : "center"
    },
  },
}), { name : "footer"})

const Footer = () => {

  const intl = useIntl()
  const lang = intl.locale

  // const styles = useStyles()

  return <div  class = "footer-container">
    {/* <Container> */}
    {/* <img src = { BG_1 } class = "footer-bg"/> */}

    <Grid container alignItem = "center" alignContent = "center" justify = "center" classes = {{ root : "footer-grid-container" }} >
      <Grid item
       xs = {footer_logo_xs}
       sm = {footer_logo_sm}
       md = {footer_logo_md}
       lg = {footer_logo_lg}
       xl = {footer_logo_xl}
       classes = {{ root : "footer-grid-logo" }}>
        <span class = "footer-logo">amoe8a</span>
      </Grid>
      <Grid item xs = {footer_xs } sm = {footer_sm} md = {footer_md} lg = {footer_lg} xl = {footer_xl} classes = {{ root : "footer-grid-item" }}>
      <div class = 'footer-grid-item2'>
        <Typography variant = "h6" component = "h3" classes = {{ root : `footer-title`}}>{intl.formatMessage({id : 'footer-contact'})}</Typography> 
        
        <div class = {`footer-content`}><a class = 'footer-link' href = "tel:+886975790326">+886975790326</a></div>
        <div class = {`footer-content`}><a class = 'footer-link' href = "mailto:info@amoe8a.com">info@amoe8a.com</a></div>
        <div class = {`footer-content footer-link`}>amoe8a.com</div>
        </div>
      </Grid>
      <Grid item xs = {footer_xs } sm = {footer_sm} md = {footer_md} lg = {footer_lg} xl = {footer_xl} classes = {{ root : "footer-grid-item" }}>
        <div class = 'footer-grid-item2'>
          <Typography variant = "h6" component = "h3" classes = {{ root : `footer-title`}}>{intl.formatMessage({id : 'footer-product'})}</Typography> 
          <div class = {`footer-content`}><Link className = "footer-link" to = {`/${lang}/solutions/pedestrian/sfz-120/`}><Typography variant = "body2" component = "h4">{intl.formatMessage({id : 'footer-speedgate'})}</Typography></Link></div>
          <div class = {`footer-content`}><Link className = "footer-link" to = {`/${lang}/solutions/pedestrian/cp-120/`}><Typography variant = "body2" component = "h4">{intl.formatMessage({id : 'footer-swinggate'})}</Typography></Link></div>
          <div class = {`footer-content`}><Link className = "footer-link" to = {`/${lang}/solutions/parking/kg-520/`}><Typography variant = "body2" component = "h4">{intl.formatMessage({id : 'footer-barrier'})}</Typography></Link></div>
        </div>
      </Grid>
      <Grid item xs = {footer_xs } sm = {footer_sm} md = {footer_md} lg = {footer_lg} xl = {footer_xl} classes = {{ root : "footer-grid-item" }}>
      <div class = 'footer-grid-item2'>

        <Typography variant = "h6" component = "h3" classes = {{ root : `footer-title`}}>{intl.formatMessage({id : 'footer-solutions'})}</Typography> 
        <div class = {`footer-content`}><Link class = "footer-link" to = {`/${lang}/solutions/pedestrian/`}><Typography variant = "body2" component = "h4"> {intl.formatMessage({id : 'footer-pedestrian'})}</Typography></Link></div>
        <div class = {`footer-content`}><Link class = "footer-link" to = {`/${lang}/solutions/aivision/`}><Typography variant = "body2" component = "h4"> {intl.formatMessage({id : 'footer-aivision'})}</Typography></Link></div>
        <div class = {`footer-content`}><Link class = "footer-link" to = {`/${lang}/solutions/parking/`}><Typography variant = "body2" component = "h4"> {intl.formatMessage({id : 'footer-parking'})}</Typography></Link></div>
        <div class = {`footer-content`}><Link class = "footer-link" to = {`/${lang}/solutions/payment/`}><Typography variant = "body2" component = "h4"> {intl.formatMessage({id : 'footer-payment'})}</Typography></Link></div>
      </div>
      </Grid>
      {/* <Grid item xs = {footer_xs } sm = {footer_sm} md = {footer_md} lg = {footer_lg} xl = {footer_xl} classes = {{ root : "footer-grid-item" }}>
      <Typography variant = "h6" classes = {{ root : `footer-title` }}>粉絲團</Typography> 
        <div class = {`footer-content`}>+886975790326</div>
      </Grid>
      /*/}
      </Grid>
    {/* </Container> */}

    <div class = 'b-link'>
      <a href = "http://www.makion.net/illustrations/item_73.html"></a>
      <a href = "https://security140611940.wordpress.com"></a>
      <a href = "https://goosafe.co">Goosafe</a>
      <a href = "https://sites.google.com/view/amoe8a-zh/">阿米巴科技股份有限公司</a>
      <a href = "https://sites.google.com/view/amoe8a-en/">Amoe8a Security Control Co., Ltd</a>
      <a href = "https://www.instagram.com/amoe8a_security/">amoe8a Instagram</a>
      <a href = "https://www.instagram.com/amoe8a_security_tw/">amoe8a Instagram</a>
      <a href = "https://www.facebook.com/Amoe8a-Security-Control-Co-Ltd-105460244986918/">amoe8a Facebook</a>
      <a href = "https://twitter.com/amoe8a_security">amoe8a Twitter</a>
    </div>
    </div>
}


export default Footer