import React, { useEffect, useState, memo } from 'react';
import BG_2 from '@src/asset/bg_2.jpg'
import Logo_White from '@src/asset/Logo_Pad_0.png';
import Logo_Blue from '@src/asset/Logo_Pad_0_Grey.png';
import { Menu } from '@src/share-component'
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setState } from '@src/reducer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { navigate, Link } from 'gatsby';
import './top.css';
import { useIntl, FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const Top = ({ bg, slogan, noSlogan, useParagraph, useFullHeight, useColorBar, imgID, sloganParagraph }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [isTop, setIsTop] = useState(true)
  const isMenuOpen = useSelector(state => state.menu)
  const scrollHandler = (e) => {
    if (window.pageYOffset == 0) { setIsTop(true); }
    else { setIsTop(false); }
  }
  const onClickMenu = () => {
    console.log('onclick Menu')
    dispatch(setState([
      { key : 'menu', value : ! isMenuOpen}
    ]))
  }

  const closeMenu = () => {
    dispatch(setState([
      { key : 'menu', value : false}
    ]))
  }
  const onClickButton = (section) => {
    navigate(section)
  }
  
  useEffect(() => {
    console.log('didMount [isTop] : ', isTop)
    const event = document.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, []);

  useEffect(() => {}, [isTop, isMenuOpen])

  useEffect(() => {

    console.log("didUpdate [isTop] : ", isTop)
  })

  const theme = useTheme();
  const matchesLogo = useMediaQuery(theme.breakpoints.down('md'));
  const matchesTopBarButtonGroup = useMediaQuery(theme.breakpoints.down('md'));
  const locale = intl.locale

  let logoClass = null
  if (matchesLogo) {

    logoClass = "top-bar-logo-color"
  }
  else {
    logoClass = "top-bar-logo-white"
    // _White

  }

  if ((!isTop) && useColorBar && (!isMenuOpen))
    logoClass = "top-bar-logo-color"
    // _White
  // if (isMenuOpen) {
  //   Logo = Logo_White
  // }
  // const Logo = matchesLogo ? Logo_Blue : Logo_White
  const Icon = isMenuOpen ? CloseIcon : MenuIcon
  return <React.Fragment>
      <div class = { (!isTop) && useColorBar && (!isMenuOpen)? 'top-bar-container-color' : 'top-bar-container'}>
        {/* <img src = {Logo} onClick = {() => { navigate(`/${locale}`); closeMenu(); }} class = {`top-bar-logo`}/> */}
        <div class = {`top-bar-logo ${logoClass}`} onClick = {() => { navigate(`/${locale}/`); closeMenu(); }}>amoe8a</div> 
          {/* 下面兩個 二選一 */}
          { matchesTopBarButtonGroup  ? 
              // <img src = {MenuImg} onClick = {onClickMenu} class = {`top-bar-menu-icon`}/>
             
              <Icon
               fontSize = "large"
               onClick = { onClickMenu }
               classes = {{ root : 'top-bar-menu-icon-color' }} /> 
              //  classes = {{ root : !isTop && useColorBar && (!isMenuOpen) ? 'top-bar-menu-icon-color' : 'top-bar-menu-icon-color' }} /> 
              
            :
            // <Grid item xs = {6} sm = {6} classes = {{ root : "top-bar-grid-item" }}>
              <ButtonGroup classes = {{ root : `top-bar-button-group` }}>
                <Button onClick = {() => navigate(`/${locale}/about/`)} color = "primary" variant = "text" disableRipple disableTouchRipple disableFocusRipple classes = {{ root : "top-bar-button",
                 label : (!isTop) && useColorBar ? `top-bar-button-label-color` : `top-bar-button-label` }}>
                   <Link className = {(!isTop) && useColorBar ? `top-link top-bar-button-label-color` : `top-link top-bar-button-label`} to = {`/${locale}/about/`}>{intl.formatMessage({ id : 'top-about'})}</Link></Button>
                <Button onClick = {() => navigate(`/${locale}/solutions/`)} color = "primary" variant = "text" disableRipple disableTouchRipple disableFocusRipple classes = {{ root : "top-bar-button", label :  (!isTop) && useColorBar ? `top-bar-button-label-color` : `top-bar-button-label`  }}><Link className = {(!isTop) && useColorBar ? `top-link top-bar-button-label-color` : `top-link top-bar-button-label`} to = {`/${locale}/solutions/`}>{intl.formatMessage({ id : 'top-solutions'})}</Link></Button>
                <Button onClick = {() => navigate(`/${locale}/contact/`)} color = "primary" variant = "text" disableRipple disableFocusRipple classes = {{ root : "top-bar-button", label :  (!isTop) && useColorBar ? `top-bar-button-label-color` : `top-bar-button-label`  }}><Link className = {(!isTop) && useColorBar ? `top-link top-bar-button-label-color` : `top-link top-bar-button-label`} to = {`/${locale}/contact/`}>{intl.formatMessage({ id : 'top-contact'})}</Link></Button>
              </ButtonGroup>
          }
      </div>
    <Menu/>
    <div class = { useFullHeight ? `top-container-fullheight` : `top-container` }>
      <img src = { typeof bg == 'undefined' ? BG_2  : bg } alt = { intl.formatMessage({ id : 'top-bg'})} class = { useFullHeight ? `top-bg-fullheight ${imgID}` : `top-bg  ${imgID}`}/>
      { noSlogan ? null : <Typography variant = "h2" component = "h1" classes = {{ root : `slogan` }}>
                          { typeof slogan == 'undefined' ? <FormattedMessage id = "top-slogan" /> : slogan }
                          </Typography>
      }
      { useParagraph ? <Typography variant = "body2" component = "h1" classes = {{ root : `top-slogan-paragraph` }}> {sloganParagraph} </Typography> : null }
    </div>
  </React.Fragment>
}

Top.defaultProps = {

  useColorBar : false
}

const areEqual = (a, b) => {
  console.log('areequal')
  console.log('a : ', a)
  console.log('b : ', b)
}

export default memo(Top, areEqual)