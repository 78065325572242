import React from 'react'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby'
import './breadcrumbs.css'

const Path = ({ slugs }) => {

  const onClick = url => navigate(url)
  return <React.Fragment>
    <Breadcrumbs aria-label = "breadcrumb" classes = {{ root : "breadcrumbs"}}>
      { slugs.map(slug => <Link onClick = { e => onClick(slug.url.toLowerCase()) } color = "primary" href = {slug.url.toLowerCase()}>{slug.name}</Link> ) } 
      {/* <Typography color="textPrimary">Pedestrian</Typography> */}
    </Breadcrumbs>
  </React.Fragment>
}


export default Path