import React from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './advice.css'
import { navigate } from 'gatsby'
import { useIntl } from "gatsby-plugin-react-intl"

const Advice = () => {

  const intl = useIntl()
  const language = intl.locale

  return  <div class = "share-advice">
  {/* <h2 id = 'index-contact-title'>{intl.formatMessage({ id : "index-advice"})}</h2> */}
  <Typography variant = "h6" component = "h2" classes = {{ root : "share-advice-title" }}>
      {intl.formatMessage({ id : "share-advice-title-1" })}
    </Typography>
  <Button onClick = {() => navigate(`/${language}/contact/`)} classes = {{ root : "share-advice-button" }}>
    <Typography variant = "h6" component = "h6" classes = {{ root : "share-advice-title2" }}>
      {intl.formatMessage({ id : "share-advice-title-2"})}
    </Typography>
  </Button>
</div>
}

export default Advice