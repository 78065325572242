import React, { useEffect } from 'react'
import clsx from 'clsx';
import { Button, ButtonBase, ButtonGroup } from '@material-ui/core';
import './menu.css'
import { useIntl, FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setState } from '@src/reducer'
import CloseIcon from '@material-ui/icons/Close';
import { navigate } from 'gatsby'

const menuItems = ["about", "solutions", "contact"]
const Menu = () => {
  // const styles = useStyles()
  const intl = useIntl()
  const isMenuOpen = useSelector(state => state.menu)
  const dispatch = useDispatch()
  // console.log('menu - body ', document.body)
  
  const sleep = (sec) =>{
    return new Promise(resolve => setTimeout(() => resolve(), sec));
  }
  useEffect(() => {
    // didUpdate
    if (isMenuOpen) { 
      // document.body.scroll = "no";
      // document.body.classList.add("menu-open-disable-scroll")
     }
    else { 
      // document.body.scroll = "yes";}
      // sleep(2000).then(() => 
      // document.body.classList.remove("menu-open-disable-scroll")
      // )
    }
  })

  const onClick = (url) => { 
    navigate(`/${intl.locale}/${url}`)
    onClickClose()
  }
  const onClickClose = () => {
    dispatch(setState([
      { key : 'menu', value : ! isMenuOpen}
    ]))
  }

  console.log('ismenuopen : ', isMenuOpen)
  return <div class = { 
    clsx(
      "menu",
      isMenuOpen && "menu-open" ,
      !isMenuOpen && "menu-close" ,
    )
  }>
    {/* <div class = 'menu-top'/> */}
    {/* <CloseIcon fontSize = "large" classes = {{ root : 'menu-button-close'}} onClick = {onClickClose}/> */}
    { menuItems.map(item => <Button onClick = { () => onClick(item) } classes = {{ 
      root : 
        clsx(
          "menu-item",
          isMenuOpen && "menu-item-open",
          !isMenuOpen &&  "menu-item-close",
        ),
      label : "menu-item-label"
      }}>{ intl.formatMessage({ id : `menu-${item}` }) }</Button>) }
  </div>

}

export default Menu