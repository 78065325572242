import React from 'react'
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-react-intl"

// import '@src/styles/fonts.css'


const backlinks = [
]

const langs = ['en', 'zh']


const Meta = ({ title, description, keywords, slug }) => {
  
  const intl = useIntl()
  return (
  <React.Fragment>
    <Helmet
      htmlAttributes = {{
        lang : intl.locale,
      }}
    >
      <title>{title}</title>
      <link rel = "alternate" href = { slug == "/" ? `https://amoe8a.com` : `https://amoe8a.com${slug}` } hreflang = "x-default"/>
      <link rel = "canonical" href = {`https://amoe8a.com/${intl.locale}${slug}`}/>
      {langs.map(lang => <link rel = "alternate" href = {`https://amoe8a.com/${lang}${slug}`} hreflang = {lang}/> )}
      <meta charSet = "utf-8"/>
      <meta name = "description" content = {description}/>
      <meta name = "viewport" content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name = "keywords" content = {keywords}/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-9DJPHNG9SY"/>
      <script>
        {
        `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9DJPHNG9SY');
        `
        }
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-SMFE858VN7"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SMFE858VN7');
        `}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-182813266-1">
      </script>
      <script>
      {` window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-182813266-1');
        `}
      </script>
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@700&display=swap" rel="stylesheet"/>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl" crossorigin="anonymous"/>
      <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap" rel="stylesheet"/>
    </Helmet>
  </React.Fragment>
  )
}

export default Meta