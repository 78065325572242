import React, { useState, useEffect } from 'react'
import './loading.css'
import clsx from 'clsx'
// import { Helmet } from "react-helmet"

const text = ["a", "m", "o", "e", "8","a"]

const Loading = ({ callback }) => {

  const [isLoading, setLoading] = useState(true)

  const sleep = (sec) =>{
    return new Promise(resolve => setTimeout(() => resolve(), sec));
  }

  useEffect(() => {
    sleep(750).then(() => {
      // console.log("setLoading")
      setLoading(!isLoading);
      sleep(250).then(() => {
        const el = document.querySelector("#loading");
        if (typeof callback != 'undefined') callback()
        if (el) {
          try { 
            // el.style.display = 'none'
            // el.remove();
           }
          catch (e) {}
        }
      })
    });
  }, []);

  useEffect(() => {
    // didUpdate
    if (isLoading) { 
      // document.body.scroll = "no";
      document.body.classList.add("loading-disable-scroll")
     }
    else { 
      // document.body.scroll = "yes";}
      // sleep(2000).then(() => 
      document.body.classList.remove("loading-disable-scroll")
      // )
    }
  })

  // if (isLoading) {
  //   return null;
  // }

  return (<React.Fragment>
    <div id = 'loading' class = {clsx(
      "loading-page",
       isLoading && "not-loaded",
       !isLoading && "loaded")}>
     
      <div class = 'loading01'>
        { text.map(t => <span>{t}</span>)}
      </div>
    </div>
    </React.Fragment>
  )

}


export default Loading